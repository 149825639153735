import React from 'react'
import { Link } from 'gatsby'
import './styles.sass'
import BlogTag from '../BlogTag'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

const CardItem = ({post, delay, colorId}) => (
  <div
    className={`content post-card color-${colorId}`}
    style={{transitionDelay: `${delay}ms`}}
  >
    <h2 className='is-size-4'>
      <Link to={post.fields.slug}>
        {post.frontmatter.title}
      </Link>
    </h2>
    <h3>
      <small style={{marginRight: '10px'}}>{post.frontmatter.date}</small>
      { post.frontmatter.tags &&
        post.frontmatter.tags.map(tag => (
          <BlogTag key={tag} tag={tag} />
        ))}
    </h3>
    <p>
      {post.excerpt}
      <br />
      <br />
      <Link className='button is-small is-primary' to={post.fields.slug}>
          Keep Reading →
      </Link>
    </p>
  </div>
)

class PostCard extends React.Component {
  state = {
    filter: '',
  }

  handleTabSelect = (value) => {
    if (value === 'All') this.setState({filter: ''})
    else this.setState({filter: value})
  }

  render () {
    const { posts } = this.props
    const renderItems = posts
      .filter(
        post => post.node.frontmatter.templateKey === 'article-page' &&
          (post.node.frontmatter.tags.indexOf(this.state.filter) > -1 || this.state.filter === '')
      )
      .map(({ node: post }, i) => {
        const delay = i * 500
        return (
          <CSSTransition
            key={`${post.fields.slug}`}
            timeout={5000 + delay}
            classNames='blogpost'
            appear
            in
            exit={false}
          >
            <CardItem post={post} delay={delay} colorId={i % 4} />
          </CSSTransition>
        )
      })

    const renderTabs = (tag) => {
      let isActive = false
      if (tag === this.state.filter) isActive = true
      if (tag === 'All' && this.state.filter === '') isActive = true
      return (
        <li className={`${isActive && 'is-active'}`} key={tag}>
          <a className={`${isActive && 'has-text-black'}`} onClick={() => this.handleTabSelect(tag)}>
            {tag}
          </a>
        </li>
      )
    }
    return (
      <div className='container'>
        <div className='columns'>
          <div className='column is-2 is-offset-1'>
            <h1 className='is-size-3 has-text-weight-semibold'>Blog</h1>
          </div>
          <div className='column is-7 is-offset-1'>
            <div className='tabs'>
              <ul>
                { this.props.filterableTags[0].map(renderTabs) }
              </ul>
            </div>
            <TransitionGroup className='blogpost-list'>
              {renderItems}
            </TransitionGroup>
          </div>
        </div>
      </div>
    )
  }
}

export default PostCard
