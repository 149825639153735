import React from 'react'
import {Link} from 'gatsby'
import './styles.sass'

const BlogTag = ({tag}) =>
  <Link to={`/tags/${tag}`}>
    <span key={tag} className='blog-tag tag is-light is-link'>{tag}</span>
  </Link>

export default BlogTag
